import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import type { NonCancelableCustomEvent } from '@amzn/awsui-components-react/polaris/internal/events';
import { ToggleProps } from '@amzn/awsui-components-react/polaris/toggle';
import { memo, useCallback, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { NAV_BAR_ID } from '../../common/constants';
import { AppContext, AppContextInfo } from '../../contexts/appContext';
import { AssetFieldInfoHelpPanel } from '../Common/AssetFieldInfoHelpPanel';
import LoginAgainModal from '../LoginAgainModal';
import TopNavigationBar from '../TopNavigationBar';

const MainApp = (): JSX.Element => {
  const [checked, setChecked] = useState(false);
  const [showLoginAgainModal, setShowLoginAgainModal] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const contextValue: AppContextInfo = useMemo(
    () => ({ setShowLoginAgainModal, setShowAssetFieldHelpPanel: setToolsOpen }),
    [],
  );

  const setToggleStatus = useCallback((event: NonCancelableCustomEvent<ToggleProps.ChangeDetail>) => {
    setChecked(event.detail.checked);
  }, []);

  const location = useLocation();

  const getContentType = (): AppLayoutProps.ContentType => {
    if (
      location.pathname.includes('manageAsset') ||
      location.pathname.includes('editAsset') ||
      location.pathname.includes('uploadAssets')
    )
      return 'form';
    else return 'table';
  };

  const showHelpPanel = useCallback(() => {
    return location.pathname.includes('manageAsset') || location.pathname.includes('editAsset');
  }, [location]);

  return (
    <div className={checked ? 'awsui-polaris-dark-mode awsui-visual-refresh' : 'awsui-visual-refresh'}>
      <AppContext.Provider value={contextValue}>
        <TopNavigationBar
          onToggle={setToggleStatus}
          checked={checked}
        />
        <LoginAgainModal visible={showLoginAgainModal} />
        <AppLayout
          headerSelector={NAV_BAR_ID}
          contentType={getContentType()}
          toolsHide={!showHelpPanel()}
          tools={<AssetFieldInfoHelpPanel />}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          navigationHide={true}
          content={
            <>
              <Outlet />
            </>
          }
        />
      </AppContext.Provider>
    </div>
  );
};

MainApp.displayName = 'MainApp';
export default memo(MainApp);
