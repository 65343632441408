import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Link from '@amzn/awsui-components-react/polaris/link';
import { memo } from 'react';

export const AssetFieldInfoHelpPanel = memo((): JSX.Element => {
  return (
    <HelpPanel
      data-testid="assetFieldHelpPanel"
      header={<h2>Asset Field Information</h2>}
    >
      <b>FBN</b> – Select the FBN related to this project from the dropdown.
      <br />
      <b>PFHO Date</b> – The PFHO date is the date when the equipment will be handed over to operations. If you are
      unsure of the exact PFHO date, or if you believe it will change, please select your best estimate at the time.
      <br />
      <b>Owned By</b> – At times AWS will ingest equipment which is owned by another entity. For example, equipment from
      a Colo Provide or a Hybrid Colo site. Please select “Building Owner” when the equipment is not owned by AWS.
      <br />
      <b>Location</b> – The location is the room in which the asset is installed. For equipment installed outside,
      choose the most specific area available. For example, “LOC.EXT.GEN – GENERATOR YARD”. When the location you would
      like to select is unavailable, please cut a ticket{' '}
      <Link
        href="https://t.corp.amazon.com/create/templates/39a75b7d-f24f-4599-824c-76725b103c39"
        target="_blank"
      >
        here
      </Link>
      .
      <br />
      <b>Assignee email</b> – Please input your email when you are requesting a new model registration (details below).
      This will allow the reviewer to contact you with questions.
      <br />
      <b>Yellow Asset Tag ID</b> – The yellow asset tag ID is a ten-digit number from a yellow barcode sticker which
      should be placed on the equipment. This should not be confused with Tag Plate ID.
      <br />
      <b>Tag Plate ID</b> – The tag plate ID, not to be confused with the Yellow Asset Tag ID, is the unique name of
      that equipment, normally applied with a metal plate or sticker from a label maker. A common example is GEN-1A.
      <br />
      <b>Manufacturer</b> – The manufacturer field is a dropdown that allows free text as well. When the manufacturer of
      the equipment which you are adding is not registered, please type in the manufacturer name in the field. A request
      will then be sent to the EAM team for review and your asset will be assigned a “Pending Review” status.
      <br />
      <b>Model</b> - The model field is a dropdown that allows free text as well. When the model of the equipment which
      you are adding is not registered, please type in the model name in the field. A request will then be sent to the
      EAM team for review and your asset will be assigned a “Pending Review” status.
      <br />
      <b>Category</b> – The category of the equipment is automatically populated based on the model. When the model is
      not registered, you will need to select the category of equipment.
      <br />
      <b>Serial Number</b> – The serial number is the unique identifier of that equipment. In cases where there is none,
      please put “NOT AVAILABLE” in this field.
      <br />
      <b>Parent Asset ID</b> – This is a required field for assets which reside in or on another piece of equipment. For
      example, breakers and relays require a parent asset. This field is only required based on the category of
      equipment.
    </HelpPanel>
  );
});

AssetFieldInfoHelpPanel.displayName = 'AssetFieldInfoHelpPanel';
