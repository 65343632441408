import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from './components/AppRouter';

const root = createRoot(document.body);
root.render(
  <BrowserRouter>
    <AppRouter />
  </BrowserRouter>,
);
