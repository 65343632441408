import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';

import { getUserCredentials } from '../../apis';

/***
 * Component to relay authCode and redirectUrl from Procore to the Asset Creator
 * Calls getSettings to get the redirectUrl
 * Calls getUserCredentials to pass the authCode and redirectUrl to the backend authenticate api which returns
 *  - Status Code 200 if the authentication succeeds on the backend and redirects to the MainApp component
 *  - Status Code 401 if the authentication fails on the backend and renders a spinner
 */
const UserCredentialsWrapper = (): JSX.Element => {
  const navigate = useNavigate();

  const location = useLocation();
  const authCode: string = location.state.code;

  // skip auth check for local testing i.e localhost
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      navigate('../mainAppAuthorised', { replace: true });
    }
  }, []);

  // Used to retrieve the redirectUrl
  const getSettings = async (): Promise<any> => {
    return await axios.get('/settings.json');
  };

  const { isLoading: isLoading, error: error, data: data } = useQuery(['settings'], getSettings);

  const authRedirectUrl = useMemo(() => {
    let redirectUrl = null;
    if (data && data.data) {
      const response = data.data;
      redirectUrl = `${response.procoreRedirectUri}`;
    }
    return redirectUrl;
  }, [isLoading, error, data]);

  // 1) Grab the token from backend after api call
  // 2) Show loading while we retrieve it
  // 3) Show list of projects i.e. redirect to the MainApp
  const {
    isLoading: isLoading2,
    error: error2,
    data: data2,
  } = useQuery({
    queryKey: ['getUserCredential', authCode, authRedirectUrl],
    queryFn: () => getUserCredentials(authCode, authRedirectUrl!),
    enabled: !!data,
  });

  useEffect(() => {
    if (data2 && data2!.status === 200) {
      navigate('../mainAppAuthorised', { replace: true });
    }
  }, [data2]);

  return (
    <Spinner
      size="large"
      variant="normal"
    ></Spinner>
  );
};

UserCredentialsWrapper.displayName = 'UserCredentialsWrapper';
export default memo(UserCredentialsWrapper);
