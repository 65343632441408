import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import * as procoreIframeHelpers from '@procore/procore-iframe-helpers';
import { useNavigate } from 'react-router-dom';

import { PROCORE_OAUTH_CALL } from '../common/constants';

export interface SuccessPayload {
  code: string;
}

export const ProcoreLandingPage = (): JSX.Element => {
  const context = procoreIframeHelpers.initialize();

  const navigate = useNavigate();

  const handleSignInClick = () => {
    context.authentication.authenticate({
      url: PROCORE_OAUTH_CALL,

      onSuccess: function (payload: SuccessPayload) {
        navigate('/mainAppAuthorize', { state: payload });
      },

      onFailure: function (error: any) {
        // eslint-disable-next-line no-console
        console.log('Error from procore authentication window', error);
      },
    });
  };

  return (
    <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
      <Box
        variant="h1"
        fontWeight="heavy"
        padding="n"
        fontSize="display-l"
      >
        Asset Collector
      </Box>
      <Box
        fontWeight="light"
        padding={{ bottom: 's' }}
        fontSize="display-l"
      >
        Fast and reliable collection for your assets
      </Box>
      <Box>
        <Button
          variant="primary"
          data-testid="loginButton"
          onClick={handleSignInClick}
        >
          Login with Procore
        </Button>
      </Box>
    </Box>
  );
};
